<template>

  <b-card-code
    title="Create Course"
  >
   <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <ValidationObserver v-slot="{ handleSubmit}">
  <b-form @submit.prevent="handleSubmit(storeCourse)">
    <b-alert
      variant="danger"
      show
      dismissible
      v-if="validationErrors && validationErrors.message"
    >
      <div class="alert-body">
        <span><strong>{{validationErrors.message}}</strong></span>
      </div>
    </b-alert>
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
            label="Course Name"
            label-for="mc-first-name"
          >
            <ValidationProvider rules="required" name="Course Name" v-slot="{ errors }">
              <b-form-input
                v-model="course.name"
                id="mc-first-name"
                placeholder="Course Name"
                name="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col>
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mt-2"
          >
            Create
          </b-button>
         
        </b-col>
        <!-- submit and reset -->
        
      </b-row>
    </b-form>
  </ValidationObserver> 
  </b-overlay>
   </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BInputGroup, BAlert, BInputGroupAppend, BCol, BAvatar,BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BOverlay, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CourseService from '@/services/course.service.js';

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BAlert
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      course: {
        name: ''
      },
      logoImg: require('@/assets/images/pages/logo-1.png'),
      avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
      showOverlay: false,
      errors: ''
    }
  },
  computed: {
    validationErrors(){
      return this.errors.data
    },
  },
  methods: {
    storeCourse() {  
      this.showOverlay = true;
          CourseService.store(this.course).then(response => {
            this.showOverlay = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Course Created Successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.$router.push('/courses');
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
            });
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
